import Vue from 'vue';
import Router from 'vue-router';
import LoginView from '../components/LoginView.vue';
import DiaryList from '../components/DiaryList.vue';
import WriteDiary from '../components/WriteDiary.vue';

Vue.use(Router);

export default new Router({
  routes: [
    { path: '/', component: LoginView },
    { path: '/diary-list', component: DiaryList, meta: { requiresAuth: true } }, // 添加meta用于权限控制
    { path: '/write-diary', component: WriteDiary, meta: { requiresAuth: true } }, // 添加meta用于权限控制
  ],
});
