<template>
  <div id="login">
    <el-form @submit.native.prevent :model="loginForm">
      <el-form-item label="Shared Password">
        <el-input v-model="loginForm.password" type="password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="loginAs('A')">☀️</el-button>
        <span style="margin: 20px; user-select: none;">❤️</span>
        <el-button type="danger" @click="loginAs('B')">🌙</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      loginForm: {
        password: '',
        user: 'A', // 默认尝试登录为A
      },
    };
  },
  methods: {
    async handleLogin() {
      try {
        if (this.loginForm.password === '') {
          alert('还没有输入密码噢');
          return;
        }
        const response = await axios.post('/api/login', {
          password: this.loginForm.password,
          user: this.loginForm.user,
        });

        if (response.data.success) {
          sessionStorage.setItem('loggedIn', 'true');
          sessionStorage.setItem('currentUser', this.loginForm.user);
          this.$router.push('/diary-list');
        } else {
          alert('密码好像输错啦，重新试试吧！');
        }
      } catch (error) {
        console.error('Login failed:', error);
        alert('登录请求未能被正确处理，可能是网络或者服务器问题..\n\n可以换个网络环境，或者稍后再试呢');
      }
    },
    loginAs(user) {
      this.loginForm.user = user;
      this.handleLogin();
    },
  },
};
</script>

<style scoped>
#login {
  width: 300px;
  margin: 100px auto;
}
</style>