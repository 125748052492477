<template>
  <div>
    <!-- 添加顶部标题栏 -->
    <div class="header-bar">
      <div class="left-section">
        <el-button type="text" class="icon-button" @click="$router.back()">
          <i class="el-icon-arrow-left icon-white"></i>
        </el-button>
      </div>
      <div class="center-section">
        <h2>{{ title }}</h2>
      </div>
      <div class="right-section">
        <el-button type="text" class="icon-button" @click="submitDiary">
          <i class="el-icon-check icon-white"></i>
        </el-button>
      </div>
    </div>

    <!-- 写日记表单 -->
    <el-form ref="diaryForm" :model="diaryForm" class="container">
      <el-form-item>
        <el-input v-model="diaryForm.title" placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          type="textarea"
          v-model="diaryForm.content"
          placeholder="请输入内容"
          rows="10"
        ></el-input>
      </el-form-item>

      <!-- 图片上传区域 -->

      <div class="upload-section">
        <div
          v-for="(image, index) in uploadedImages"
          :key="index"
          class="uploaded-image"
        >
          <img v-if="isImage(image.url)" :src="image.url" class="thumbnail" alt="Uploaded Image" @click="showImage(image.url)"/>
          <div v-else-if="isVideo(image.url)" class="video-cover">
            <!--<video autoplay muted :src="image.url" class="thumbnail video-thumbnail" alt="Diary Video" preload="metadata" @click="showMedia(image.url)" @canplay="canplay($event)">-->
            <video autoplay muted :src="image.url+'#t=0.01'" class="thumbnail video-thumbnail" alt="Diary Video" preload="metadata" @click="showMedia(image.url)" @canplay="canplay($event)">
              您的浏览器不支持视频播放。 
            </video>
            <!-- 播放图标 -->
            <div class="play-icon">
              <i class="el-icon-video-play" style="color: #fff; font-size: 24px;"></i> 
            </div>
          </div>
          <div v-else-if="isAudio(image.url)" :key="'audio-'+index" class="video-cover">
            <div :class="{'thumbnail-audio-blue': editingDiary.Author === 'A', 'thumbnail-audio-red': editingDiary.Author === 'B'}">
              <img src="cover-audio.png" class="cover-audio" @click="showMedia(image.url)">
            </div>
            <!-- 播放图标 -->
            <div class="play-icon">
              <i class="el-icon-video-play" style="color: #fff; font-size: 24px;"></i> 
            </div> 
          </div>
          <i class="el-icon-close" @click="removeImage(index)"></i>
        </div>

        <!-- 图片放大查看对话框 -->
        <el-dialog title="查看" :visible.sync="imgDialogVisible" custom-class="fullscreen-dialog" @close="dialogClose">
          <img v-if="isImage(previewImageUrl)" :src="previewImageUrl" style="max-width: 100%; height: auto;" class="fullscreen-image">
          <video v-else-if="isVideo(previewImageUrl)" :src="previewImageUrl+'#t=0.01'" class="fullscreen-image" controls preload="metadata" alt="Diary Video" ref="dialogVideoRef">
            您的浏览器不支持视频播放
          </video> 
          <audio v-else-if="isAudio(previewImageUrl)" controls ref="dialogAudioRef">
            <source :src="previewImageUrl" type="audio/mpeg">
            您的浏览器不支持 audio 标签。
          </audio>
          <span slot="footer" class="dialog-footer">
            <el-button type="danger" @click="removeImage(index); imgDialogVisible=false">删除</el-button>
            <el-button @click="imgDialogVisible=false">关闭</el-button>
          </span>
        </el-dialog>

        <el-progress type="circle" v-if="isUploading" :width="50" :percentage="uploadProgress" style="margin-right: 10px"></el-progress>

        <!-- 在上传图片按钮下方添加隐藏的文件输入框 -->
        <input
        ref="fileInput"
        type="file"
        accept="image/*, video/*, audio/*, .mp3, .m4a, .wav, .flac, .aac, .ogg, .alac, .aif, .aiff"
        style="display: none"
        @change="onFileSelected"
        />
        <!-- 上传图片按钮点击事件为触发文件选择 -->
        <el-button type="primary" plain icon="el-icon-upload2" @click="selectImage" :loading="isUploading">
          上传图片
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import axios from 'axios';
import router from '@/router';

export default {
  data() {
    return {
      title: "写日记",
      diaryForm: {
        title: '', // 初始标题为空
        content: '', // 初始内容为空
      },
      uploadedImages: [], // 存储已上传图片的信息
      editingDiary: null, // 用于存储编辑的日记ID
      imgDialogVisible: false,
      previewImageUrl: '', // 用于存储预览的图片URL
      isUploading: false,
      uploadProgress: 10.0,
    };
  },
  methods: {
    isImage(url) {
      return /\.(jpg|jpeg|png|gif|bmp|tiff|svg|ico|webp|heic|heif)$/i.test(url);
    },
    isVideo(url) {
      return /\.(mov|mp4|webm|ogg|avi|wmv|mkv|flv|mpeg|mpg|rm|rmvb|ts|vob|mp4v|3gp)$/i.test(url);
    },
    isAudio(url) {
      return /\.(mp3|m4a|flac|wav|wma|aac|alac|ogg|opus|aiff|aif|mid|midi)$/i.test(url);
    },
    dialogClose() {
      if (this.isVideo(this.previewImageUrl)) {
        this.$refs.dialogVideoRef.pause();
      } else if (this.isAudio(this.previewImageUrl)) {
        this.$refs.dialogAudioRef.pause();
      }
    },
    showMedia(url) {
      this.previewImageUrl = url;
      this.imgDialogVisible = true;
    },
    canplay(event){
      setTimeout(() => {
        event.target.pause(); // 暂停播放视频 以此来获取封面
      }, 0);
    },
    async submitDiary() {
      // 构建日记数据对象
      const newDiary = {
        Title: this.diaryForm.title,
        Content: this.diaryForm.content,
        ImageURL: this.uploadedImages.map(img => img.url).join(','), // 合并图片URL
        Date: new Date(), // 当前时间
        Read: false, // 默认未读
        Author: sessionStorage.getItem('currentUser'), // 假设当前用户存储在sessionStorage
      };

      if (this.editingDiary) {
        // 如果有编辑ID，则更新日记
        try {
          const response = await axios.patch(`/api/diary/${this.editingDiary.ID}`, newDiary);
          if (response.status === 200) {
            this.$message.success('日记更新成功啦！');
            // 重置表单或跳转页面等操作
            this.resetForm();
            localStorage.removeItem('draftDiary');
            localStorage.removeItem('draftDiaryImages');
            router.push('/diary-list');
          } else {
            this.$message.error('更新日记时发生错误！');
          }
        } catch (error) {
          console.error('Error submitting diary:', error);
          this.$message.error('网络错误，提交失败！');
        }
      } else {
        try {
          const response = await axios.post('/api/diary', newDiary); // 假定/api是你的API前缀
          if (response.status === 201) { // HTTP 201 Created
            this.$message.success('日记发布成功啦！');
            // 重置表单或跳转页面等操作
            this.resetForm();
            localStorage.removeItem('draftDiary');
            localStorage.removeItem('draftDiaryImages');
            router.push('/diary-list');
          } else {
            this.$message.error('发布日记时发生错误！');
          }
        } catch (error) {
          console.error('Error submitting diary:', error);
          this.$message.error('网络错误，提交失败！');
        }
      }
    },
    resetForm() {
      this.diaryForm.title = '';
      this.diaryForm.content = '';
      this.uploadedImages = []; // 重置图片列表
    },
    selectImage() {
      // 触发文件输入框的点击事件
      this.$refs.fileInput.click();
    },
    onFileSelected(event) {
      const file = event.target.files[0];
      if (file) {
        this.uploadImage(file);
      }
      this.$refs.fileInput.value = null;
    },
    async uploadImage(file) {
      const formData = new FormData();
      formData.append('image', file);

      try {
        this.isUploading = true;
        const response = await axios.post('/api/upload-image', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: progressEvent => {
            this.uploadProgress = (progressEvent.loaded / progressEvent.total * 100 | 0);
          },
        });

        if (response.status === 200 && response.data.imageUrl) {
          // 成功上传，将URL添加到已上传列表
          this.uploadedImages.push({ url: response.data.imageUrl });
          this.autoSave();
          this.$message.success('上传成功啦！');
        } else {
          this.$message.error('上传失败了，重新试试看吧');
        }
      } catch (error) {
        console.error('图片上传错误:', error);
        this.$message.error('上传过程中出错啦！');
      }
      this.isUploading = false;
      this.uploadProgress = 0.0;
    },
    removeImage(index) {
      this.uploadedImages.splice(index, 1); // 移除指定索引的图片
      this.autoSave();
    },
    showImage(imageUrl) {
      this.previewImageUrl = imageUrl;
      this.imgDialogVisible = true;
    },
    // 自动保存逻辑
    autoSave() {
      if (this.editingDiary != null) {
        return; // 对于编辑旧日记，不保存草稿
      }
      localStorage.setItem('draftDiary', JSON.stringify(this.diaryForm));
      localStorage.setItem('draftDiaryImages', JSON.stringify(this.uploadedImages));
    },
  },
  mounted() {
    // 页面加载时尝试恢复上次保存的草稿
    const draftDiary = localStorage.getItem('draftDiary');
    const draftDiaryImages = localStorage.getItem('draftDiaryImages');
    // 页面加载时尝试从路由查询中获取编辑ID
    if (this.$route.query.editDiary != null /*|| editingDiary != null*/) {
        if (this.$route.query.editDiary != null) {
            this.editingDiary = this.$route.query.editDiary;
        } else {
            //this.editingDiary = JSON.parse(editingDiary);
        }
        this.title = "编辑日记";
        this.diaryForm.title = this.editingDiary.Title;
        this.diaryForm.content = this.editingDiary.Content;
        this.uploadedImages = [];
        this.editingDiary.ImageURL.split(',').forEach(imageUrl => {
            if(imageUrl != '') {
              this.uploadedImages.push({
                  url: imageUrl
              });
            }
        });
        this.$message.info('编辑旧日记不会保存草稿哦，记得及时提交～');
    } else if (draftDiary) {
      try {
        this.diaryForm = JSON.parse(draftDiary);
        this.uploadedImages = JSON.parse(draftDiaryImages);
        if (this.diaryForm.title != '' || this.diaryForm.content != '' || this.uploadedImages.length > 0) {
            this.$message.success('发现上次没写完的草稿噢！可以继续写啦！');
        }
      } catch (e) {
        console.error('Failed to load draft:', e);
      }
    }
    // 监听表单变化自动保存
    this.$watch('diaryForm', this.autoSave, { deep: true });
  },
};
</script>

<style>
body {
    background: rgb(245,251,255);
    margin: 0;
}

.header-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  margin-bottom: 20px;
  background-color: rgb(248,99,131);
  border-bottom: 1px solid #eee;
}

.left-section,
.center-section,
.right-section {
  display: flex;
  align-items: center;
}

h2 {
  margin: 0;
  font-size: 18px;
  color: #fff;
}
.icon-button {
  overflow: hidden;
  font-size: 18px;
  width: 40px; /* 根据需要调整按钮大小 */
  height: 40px; /* 与宽度保持一致，形成正方形按钮 */
}

.icon-white {
  color: #ffffff; /* 设置图标颜色为白色 */
}

.container {
   margin: 12px; 
}

.upload-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
}

.uploaded-image {
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 2px dashed #ccc;
  border-radius: 6px;
  width: 100px;
  height: 100px;
  padding: 4px;
}

.thumbnail {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  background: #999;
}

.uploaded-image .el-icon-close {
  position: absolute;
  top: -0rem;
  right: -0rem;
  cursor: pointer;
  color: #fff;
  background: #000;
  border-radius: 50%;
}

.fullscreen-dialog {
  /* 移除默认宽度，使对话框全宽 */
  width: 95% !important;
  /* 移除默认最大宽度，允许全屏 */
  max-width: none !important;
  /* 设置对话框高度为100%，覆盖整个视口高度 */
  max-height: none !important;
  /* 确保内容区域也铺满 */
}
/* 让图片自适应 */
.fullscreen-image {
  max-width: 100%;
  max-height: 100%;
  display: block; /* 确保图片作为块级元素显示 */
  margin: auto; /* 居中图片 */
}

.thumbnail-audio-blue {
  position: relative;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  /*background: #72c16c;*/
  background: rgb(122,188,212);
}
.thumbnail-audio-red {
  position: relative;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  /*background: #72c16c;*/
  background: rgb(248,99,131);
}
.cover-audio {
  width: 50px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
</style>