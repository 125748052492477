<template>
  <div>
    <!-- 添加顶部标题栏 -->
    <div class="header-bar">
      <div class="left-section">
        <el-button type="text" class="icon-button" @click="logout">
          <i class="el-icon-switch-button icon-white"></i>
        </el-button>
      </div>
      <div class="center-section">
        <h2>日记本</h2>
      </div>
      <div class="right-section">
        <el-button type="text" class="icon-button" @click="navigateToWriteDiary">
          <i class="el-icon-edit icon-white"></i>
        </el-button>
      </div>
    </div>

    <!-- Diary List -->
    <el-row v-for="diary in diaries" :key="diary.ID" class="container">
      <el-col :class="{'red-border': diary.Author === 'B', 'blue-border': diary.Author === 'A', 'diary-entry-container' : true}">
        <div :class="{'diary-entry': !bookmarkStyle, 'bookmark-style-diary-entry': bookmarkStyle}">
          <div :class="{'header': !bookmarkStyle, 'bookmark-style-header': bookmarkStyle}" @click="toggleExpand(diary.ID)">
            <div v-if="bookmarkStyle" class="love-bookmark">
              <img class="bookmark-img" :src=" diary.Author == 'A' ? 'diary-love-blue.jpg' : 'diary-love-red.jpg'">
            </div>
            <h4
            :class="{'title': !bookmarkStyle, 'bookmark-style-title': bookmarkStyle, 'blue-color': diary.Author === 'A', 'red-color': diary.Author === 'B'}"
            >{{ diary.Title }}
            </h4>
            <div :class="{'right-aligned-items}': !bookmarkStyle, 'bookmark-style-right-aligned-items': bookmarkStyle}">
                <span class="text-right" :class="{'date': !bookmarkStyle, 'bookmark-style-date':bookmarkStyle, 'blue-color': diary.Author === 'A', 'red-color': diary.Author === 'B'}">{{ diary.Date }}</span>
            </div>
          </div>
          <hr v-if="!bookmarkStyle" :class="{'blue-hr': diary.Author === 'A', 'red-hr': diary.Author === 'B'}">
          <div @click="toggleExpand(diary.ID)" :class="{ 'content': !bookmarkStyle, 'bookmark-style-content': bookmarkStyle, expanded: isExpanded(diary.ID) }" :style="{ maxHeight: isExpanded(diary.ID) ? 'none' : '100px', overflow: 'hidden' }">
            <div>{{ diary.Content }}</div>
            <div class="expand-button-group">
              <el-button
                    v-if="!isExpanded(diary.ID)"
                    type="text"
                    icon="el-icon-arrow-down"
                    :class="{'blue-background': diary.Author === 'A', 'red-background': diary.Author === 'B'}"
                  ></el-button>
                  <el-button
                    v-if="isExpanded(diary.ID)"
                    type="text"
                    icon="el-icon-arrow-up"
                    :class="{'blue-background': diary.Author === 'A', 'red-background': diary.Author === 'B'}"
                  ></el-button>
            </div>
          </div>
          <div v-if="diary.ImageURL" class="image-thumbnails">
              <!-- 缩略图部分 -->
              <template v-for="(url, index) in diary.ImageURL.split(',')" > 
                <img v-if="isImage(url)" :key="'image-'+index" :src="url" class="thumbnail" alt="Diary Image" @click="showMedia(url)"> 
                <div v-else-if="isVideo(url)" :key="'video-'+index" class="video-cover">
                   <!--<video autoplay muted :src="url" class="thumbnail video-thumbnail" alt="Diary Video" preload="auto" @click="showMedia(url)" @canplay="canplay($event)"> -->
                   <video :src="url+'#t=0.01'" class="thumbnail video-thumbnail" alt="Diary Video" preload="auto" @click="showMedia(url)" @canplay="canplay($event)"> 
                    您的浏览器不支持视频播放。 
                  </video>
                  <!-- 播放图标 -->
                  <div class="play-icon">
                    <i class="el-icon-video-play" style="color: #fff; font-size: 24px;"></i> 
                  </div> 
                </div>
                <div v-else-if="isAudio(url)" :key="'audio-'+index" class="video-cover">
                  <div :class="{'thumbnail-audio-blue': diary.Author === 'A', 'thumbnail-audio-red': diary.Author === 'B'}">
                    <img src="cover-audio.png" class="cover-audio" @click="showMedia(url)">
                  </div>
                  <!-- 播放图标 -->
                  <div class="play-icon">
                    <i class="el-icon-video-play" style="color: #fff; font-size: 24px;"></i> 
                  </div> 
                </div>
              </template> 
            </div>
          <div v-if="diary.Author === currentUser && isExpanded(diary.ID)" class="button-group">
            <el-button type="primary" round @click="editDiary(diary)">
              <i class="el-icon-edit"></i> 编辑
            </el-button>
            <el-button type="danger" round @click="openDeleteConfirmation(diary.ID)">
              <i class="el-icon-delete"></i> 删除
            </el-button>
          </div>
        </div>
      </el-col>
    </el-row>

    <!-- 图片放大查看对话框 -->
    <el-dialog title="查看" :visible.sync="imgDialogVisible" custom-class="fullscreen-dialog" @close="dialogClose">
      <img v-if="isImage(previewImageUrl)" :src="previewImageUrl" style="max-width: 100%; height: auto;" class="fullscreen-image">
      <video v-else-if="isVideo(previewImageUrl)" :src="previewImageUrl+'#t=0.01'" class="fullscreen-image" controls preload="metadata" alt="Diary Video" ref="dialogVideoRef">
        您的浏览器不支持视频播放
      </video> 
      <audio v-else-if="isAudio(previewImageUrl)" controls ref="dialogAudioRef" preload="metadata" :src="previewImageUrl" type="audio/mpeg">
          您的浏览器不支持 audio 标签。
      </audio>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="downloadDialogMedia(previewImageUrl)">下载</el-button>
        <el-button @click="imgDialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>

    <!-- Infinite Scroll Handling -->
    <infinite-loading @infinite="loadMore" spinner="spinning-circles" class="infinite-loading">
      <span slot="no-more">滑到底了，没有更多日记啦～</span>
      <span slot="error">啊哦，加载出了点问题，重新加载试试吧？</span>
      <span slot="no-results">还没有日记哦，快去给Ta写一篇吧！</span>
      <span slot="spinner">努力加载中...！</span>
    </infinite-loading>

    <el-dialog
    title="确认删除"
    :visible.sync="dialogVisible"
    width="240px"
    :before-close="handleClose"
  >
    <p>确定要删除这条日记吗？</p>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取消</el-button>
      <el-button type="danger" @click="confirmDelete">确定</el-button>
    </span>
  </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import InfiniteLoading from 'vue-infinite-loading';

export default {
  data() {
    return {
      diaries: [],
      loading: false,
      page: 1,
      perPage: 10,
      expandedDiary: "",
      currentUser: sessionStorage.getItem('currentUser'), // 当前登录用户的标识
      hasMore: true,
      dialogVisible: false, // 控制删除确认对话框的显示状态
      currentDeletingDiaryId: null, // 记录当前待删除的日记ID
      imgDialogVisible: false,
      previewImageUrl: '', // 用于存储预览的图片URL
      bookmarkStyle: true,
    };
  },
  components: {
    InfiniteLoading,
  },
  methods: {
    isImage(url) {
      return /\.(jpg|jpeg|png|gif|bmp|tiff|svg|ico|webp|heic|heif)$/i.test(url);
    },
    isVideo(url) {
      return /\.(mov|mp4|webm|ogg|avi|wmv|mkv|flv|mpeg|mpg|rm|rmvb|ts|vob|mp4v|3gp)$/i.test(url);
    },
    isAudio(url) {
      return /\.(mp3|m4a|flac|wav|wma|aac|alac|ogg|opus|aiff|aif|mid|midi)$/i.test(url);
    },
    dialogClose() {
      if (this.isVideo(this.previewImageUrl)) {
        this.$refs.dialogVideoRef.pause();
      } else if (this.isAudio(this.previewImageUrl)) {
        this.$refs.dialogAudioRef.pause();
      }
    },
    showMedia(url) {
      this.previewImageUrl = url;
      this.imgDialogVisible = true;
    },
    showImage(imageUrl) {
      this.previewImageUrl = imageUrl;
      this.imgDialogVisible = true;
    },
    downloadDialogMedia(url) {
      var downloadLink = document.createElement('a');
      downloadLink.href = url;  // 替换为你要下载的文件路径
      downloadLink.click();
    },
    canplay(event){
      setTimeout(() => {
        event.target.pause(); // 暂停播放视频 以此来获取封面
      }, 0);
    },
    async loadMore($state) {
      console.log ('loadMore')
      this.loading = true;
      try {
        const response = await axios.get(`/api/diaries?page=${this.page}`);
        if(response.data.length > 0) {
            let options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                //hour: '2-digit',
                //minute: '2-digit',
                //second: '2-digit',
                hour12: false, // 使用24小时制
            };
            let formatter = new Intl.DateTimeFormat('default', options);
            response.data.forEach((item, i, arr) => {
                arr[i].Date = formatter.format(new Date(item.CreatedAt)); // UpdatedAt
            });
            this.diaries.push(...response.data);
            this.page++;
            $state.loaded();
        } else {
            this.hasMore = false; // 没有更多数据了
            $state.complete(); 
        }
        
      } catch (error) {
        console.error(error);
        $state.error()
      }
      this.loading = false;
    },

    openDeleteConfirmation(diaryId) {
      this.currentDeletingDiaryId = diaryId;
      this.dialogVisible = true;
    },
    handleClose(done) {
      this.dialogVisible = false;
      done(); // 确保对话框正确关闭
    },
    confirmDelete() {
      this.deleteDiary(this.currentDeletingDiaryId);
      this.dialogVisible = false;
      this.currentDeletingDiaryId = null;
    },
    async deleteDiary(id) {
      // Implement logic to check if the user can delete the diary
      try {
        await axios.delete(`/api/diary/${id}`);
        this.diaries = this.diaries.filter(diary => diary.ID !== id);
        this.$message.success('日记删除成功啦！');
      } catch (error) {
        console.error(error);
      }
    },

    toggleExpand(diaryId) {
      if(diaryId === this.expandedDiary) {
        this.expandedDiary = null;
      } else {
        this.expandedDiary = diaryId;
      }
    },
    isExpanded(diaryId) {
        return this.expandedDiary == diaryId;
    },
    editDiary(diary) {
      // 跳转到编辑页面或弹出编辑对话框的逻辑
      this.$router.push({ path: '/write-diary', query: { editDiary: diary,  } });
    },
    logout() {
      // 清理登录状态，例如移除sessionStorage中的登录标记
      sessionStorage.removeItem('loggedIn');
      sessionStorage.removeItem('currentUser');
      this.deleteCookie('mysession');
      // 跳转到登录页面或其他适当页面
      this.$router.push('/');
    },
    navigateToWriteDiary() {
      // 跳转到写日记页面
      this.$router.push('/write-diary');
    },
    deleteCookie(name) {
      document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }
  },
  mounted() {
    if(sessionStorage.getItem('loggedIn') == null) {
      this.$router.push('/');
    }
  },
};
</script>

<style>
body {
    background: rgb(245,251,255);
    margin: 0;
}

.header-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  margin-bottom: 20px;
  background-color: rgb(248,99,131);
  border-bottom: 1px solid #eee;
}

.left-section,
.center-section,
.right-section {
  display: flex;
  align-items: center;
}

h2 {
  margin: 0;
  font-size: 18px;
  color: #fff;
}

h4 {
    margin: 0px;
}

.icon-button {
  overflow: hidden;
  font-size: 18px;
  width: 40px; /* 根据需要调整按钮大小 */
  height: 40px; /* 与宽度保持一致，形成正方形按钮 */
}

.icon-white {
  color: #ffffff; /* 设置图标颜色为白色 */
}


.red-border {
    border: 1.5px solid rgb(248,99,131);
    border-radius: 10px;
}
.blue-border {
    border: 1.5px solid rgb(122,188,212);
    border-radius: 10px;
}
.red-hr {
    border-top-style: solid;
    border-top-color: rgb(248,99,131);
    border-bottom: none;
}
.blue-hr {
    border-top-style: solid;
    border-top-color: rgb(122,188,212);
    border-bottom: none;
}

.container {
   margin: 12px; 
}

.diary-entry {
  padding: 1rem;
  padding-top: 0.7rem;
}
.bookmark-style-diary-entry {
  padding: 1rem;
  padding-top: 0.2rem;
}

.diary-entry-container {
    background: #fff;
    margin-bottom: 12px;
}

.header {
  display: flex;
  align-items: center;
}
.bookmark-style-header {
  display: flex;
}

.title {
  flex-grow: 1; /* 让标题独占左侧空间 */
  text-align: left;
}
.bookmark-style-title {
  flex-grow: 1; /* 让标题独占左侧空间 */
  text-align: left;
  margin-left: 1.7rem;
  font-size: 1.1rem;
  font-weight: 500;
  min-height: 1.56rem;
}

.right-aligned-items { /* 新增类，用于包含日期和按钮 */
  display: flex;
  align-items: center; /* 保持垂直居中 */
  justify-content: flex-end; /* 让内部元素靠右对齐 */
}
.bookmark-style-right-aligned-items { /* 新增类，用于包含日期和按钮 */
  display: flex;
  align-items: flex-end; /* 保持垂直居中 */
  justify-content: flex-end; /* 让内部元素靠右对齐 */
}

.date {
  font-size: 0.9em;
  color: #999;
}
.bookmark-style-date {
  font-size: 0.8em;
  color: #999;
}

.red-color {
    color: rgb(248,99,131);
}
.blue-color {
    color: rgb(122,188,212);
}
.red-background i {
    background-color: rgb(248,99,131);
}
.blue-background i {
    background-color: rgb(122,188,212);
}
.el-icon-arrow-down {
  border-radius: 50%;
  padding: 2px;
  color: #fff;
}
.el-icon-arrow-up {
  border-radius: 50%;
  padding: 2px;
  color: #fff;
}
.expand-button-group {
  position: absolute;
  right: 0px;
  bottom: 0px;
}
.expand-button-group button{
  padding: 0;
}

.text-right {
    text-align: right;
}
.content {
  position: relative;
  transition: max-height 0.3s ease;
  overflow: hidden;
  text-align: left;
  white-space: pre-wrap;
  margin: 20px 0px;
}
.bookmark-style-content {
  position: relative;
  transition: max-height 0.3s ease;
  overflow: hidden;
  text-align: left;
  white-space: pre-wrap;
  margin-top: 5px;
}

.bookmark-img {
  user-select: none;
  -webkit-user-drag: none;
}

.expanded {
  max-height: none;
}
.image-thumbnails {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
}
.thumbnail {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  background: #999;
}
.thumbnail-audio-blue {
  position: relative;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  /*background: #72c16c;*/
  background: rgb(122,188,212);
}
.thumbnail-audio-red {
  position: relative;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  /*background: #72c16c;*/
  background: rgb(248,99,131);
}
.cover-audio {
  width: 50px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.button-group {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px; /* 根据需要调整留白 */
  box-sizing: border-box;
}
.button-group button {
  width: calc(50% - 10px); /* 让两个按钮加上间隙正好占满宽度，10px是假设的间隙 */
  border-radius: 20px; /* 圆角矩形效果 */
}
.infinite-loading {
  margin-top: 15px;
  margin-bottom: 22px;
  font-size: 14px;
  color: #999;
}

.fullscreen-dialog {
  /* 移除默认宽度，使对话框全宽 */
  width: 95% !important;
  /* 移除默认最大宽度，允许全屏 */
  max-width: none !important;
  /* 设置对话框高度为100%，覆盖整个视口高度 */
  max-height: none !important;
  /* 确保内容区域也铺满 */
}
/* 让图片自适应 */
.fullscreen-image {
  max-width: 100%;
  max-height: 100%;
  display: block; /* 确保图片作为块级元素显示 */
  margin: auto; /* 居中图片 */
}

.love-bookmark {
  position: absolute;
  left: 0.7rem;
  top: 0px;
}

.love-bookmark img {
  width: 1.5rem;
}

.video-cover {
  position: relative; /* 使子元素可以使用绝对定位 */
}

.play-icon {
  position: absolute;
  pointer-events: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* 居中对齐 */
  width: 2.5rem;
  height: 2.5rem;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色背景 */
  border-radius: 50%; /* 圆形图标 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1; /* 确保图标在封面图之上 */
}

</style>